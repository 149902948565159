import styled from '@emotion/styled';
import {
  BadgeDisplay,
  BaseTheme,
  Box,
  Button1,
  CreatorBio,
  dim,
  Header6,
  Header7,
  ReadingExperience,
  ReadingListEntry,
  ReadingListsOverlay,
  RecentlyRead,
  RecentlyReadStory,
  RecommendedReading,
  RecommendedStory,
  ShareFeedback,
  ShareOverlay,
  SocialMediaBar,
  StoryInfo,
  StoryInfoContentAuthors,
  StoryInfoContentCharacter,
  StoryInfoContentCharacters,
  StoryInfoContentLocation,
  StoryInfoContentMedium,
  StoryInfoContentSummary,
  StoryInfoMobile,
  StoryIntro,
  StoryRoundedBox,
  VerticalComicCardArt,
} from '@astc/frontend-components';
import { down } from 'styled-breakpoints';
import React, { useCallback, useContext, useEffect } from 'react';
import { PanelContainer } from '@/components/panels/PanelContainer';
import {
  isPanelInteractive,
  RecentlyReadStoryResult,
  StoryProps,
} from '@/types';
import { SourcedImage } from '@/components/media/SourcedImage';
import { StoryHeroBanner } from '@/components/story/StoryHeroBanner';
import { StoryModeControl } from '@/components/story/StoryModeControl';
import { useDispatch, useSelector } from 'react-redux';
import { updateMode } from '@/app/features/user/slice';
import {
  useCheckStoryInReadingList,
  useFetchReadingLists,
  useGetRecentlyReadStories,
  usePostReadingList,
} from '@/app/features/readingLists/hooks';
import {
  deleteReadingListStory,
  postReadingListStory,
} from '@/app/features/readingLists/thunks';
import { StoryContext } from '@/contexts/StoryContext';
import { useFetchUser } from '@/app/features/user/hooks';
import { addRecentlyRead } from '@/app/features/readingLists/slice';
import {
  Maybe,
  SanityImage,
  SanityStoryRecommended,
} from '../../../../graphql-types';
import { navigate } from 'gatsby';
import {
  useFetchFeedbacks,
  usePostFeedback,
} from '@/app/features/feedbacks/hooks';
import { selectFeedbackById } from '@/app/features/feedbacks/slice';
import { RootState } from '@/app/store';
import { fetchAuthedData } from '@/app/readerDataClient';
import { convertSanityBlockToPlainText } from '@/utils/sanity';
import { redirectOnShareStory } from '@/utils/share';
import { Icon } from '@iconify/react';
import downloadIcon from '@iconify/icons-bx/download';
import { useHasUser } from '@/utils/hooks';
import {
  useAwardInteractiveBadge,
  useAwardReadingBadge,
} from '@/app/features/badges/hooks';
import { useFetchPanelResponses } from '@/app/features/panelResponses/hooks';

export type StoryPageProps = Omit<StoryProps['pageContext'], '_id'>;

const StyledStoryPage = styled(Box)`
  display: flex;
  box-sizing: border-box;
  flex-direction: column;
  align-items: center;
  width: ${dim(1040)};
  ${down('mobile')} {
    width: 100%;
  }
`;

const StyledStoryEndPage = styled(Box)`
  display: flex;
  box-sizing: border-box;
  flex-direction: column;
  align-items: center;
  width: 100%;
  background-color: ${({ theme }) => (theme as BaseTheme).colors.grays[10]};
  padding-bottom: ${({ theme }) => (theme as BaseTheme).space[5]};
  box-shadow: inset 0 ${dim(8)} ${dim(4)} ${dim(-4)} rgba(0, 0, 0, 0.25);
  ${down('mobile')} {
    width: 100%;
    background-color: ${({ theme }) => (theme as BaseTheme).colors.white};
  }
`;

const StoryPagePanels = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  background-color: ${({ theme }) => (theme as BaseTheme).colors.white};
`;

const StoryPageBadgeDisplay = styled(StoryRoundedBox)`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
  margin-top: ${({ theme }) => (theme as BaseTheme).space[4]};
  padding-top: ${({ theme }) => (theme as BaseTheme).space[4]};
`;

const StoryPageCreators = styled(StoryRoundedBox)`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
  margin-top: ${({ theme }) => (theme as BaseTheme).space[4]};

  ${down('mobile')} {
    padding-top: ${({ theme }) => (theme as BaseTheme).space[3]};
    padding-bottom: ${({ theme }) => (theme as BaseTheme).space[3]};
  }
  padding-top: ${({ theme }) => (theme as BaseTheme).space[5]};
  padding-bottom: ${({ theme }) => (theme as BaseTheme).space[5]};
`;

const StoryPageAnswers = styled(Box)`
  padding: ${({ theme }) => (theme as BaseTheme).space[4]} ${dim(60)};
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  justify-content: center;
  margin-top: ${({ theme }) => (theme as BaseTheme).space[4]};
  padding-top: ${({ theme }) => (theme as BaseTheme).space[4]};

  ${down('mobile')} {
    margin: ${({ theme }) => (theme as BaseTheme).space[3]};
    padding: ${({ theme }) => (theme as BaseTheme).space[4]} 0 0 0;
    width: calc(100vw - 2rem);
    border-top-width: ${dim(1)};
    border-top-style: solid;
    border-top-color: ${({ theme }) => (theme as BaseTheme).colors.grays[20]};
  }
`;

const StoryPageAnswerButtons = styled(Box)`
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  justify-content: center;
  ${down('mobile')} {
    flex-direction: column;
  }
`;

const CreatorWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  flex-basis 100%;
  flex-shrink: 0;

  > div:last-of-type {
    margin-bottom: 0;
  }
`;

export const StoryPage: React.FC<StoryPageProps> = ({
  title,
  genres = [],
  creators = [],
  countries = [],
  characters = [],
  medium,
  location,
  description,
  panels,
  recommended,
  badges,
  images: {
    thumbnail: { mobile },
    heroBanner,
  },
}) => {
  const dispatch = useDispatch();
  const [showInfo, setShowInfo] = React.useState(false);
  const [readingListOverlay, setReadingListOverlay] = React.useState(false);
  const [shareOverlay, setShareOverlay] = React.useState(false);

  const { storyId } = useContext(StoryContext) || {};

  const hasUser = useHasUser();

  useAwardReadingBadge(storyId, hasUser);
  useAwardInteractiveBadge(storyId, hasUser);
  useFetchPanelResponses();

  const { readingLists } = useFetchReadingLists();
  const { listCount } = useCheckStoryInReadingList(storyId);
  const { user } = useFetchUser();
  useFetchFeedbacks();
  const { handleFeedback } = usePostFeedback(storyId || '', 'story');
  const hasFeedback = useSelector(
    (state: RootState) =>
      !!(storyId && selectFeedbackById(state.feedbacks, `story:${storyId}`)),
  );

  useEffect(() => {
    storyId && dispatch(addRecentlyRead({ id: storyId + '' }));
  }, []);

  const recentlyReadEntries = useGetRecentlyReadStories(storyId);

  const { handlePostReadingList } = usePostReadingList();

  const handleShowInfo = useCallback(() => {
    setShowInfo((prev) => !prev);
  }, [setShowInfo]);

  const onBookmarkClick = useCallback(() => {
    setReadingListOverlay(true);
  }, [setReadingListOverlay]);

  // only show reading lists the user has created
  // TODO: create a unit test for this
  const readingListEntries: ReadingListEntry[] = readingLists
    .filter((readingList) => readingList.readingList?.owner)
    .map((readingListResult) => ({
      name: readingListResult.readingList?.name || '',
      id: readingListResult.readingList?.id || '',
      length: readingListResult.readingList?.stories.length || 0,
      selected: !!readingListResult.readingList?.stories.find(
        (story) => story.id === storyId,
      ),
    }));

  const onReadingListCreate = (name: string, description: string) => {
    handlePostReadingList(name, description);
  };

  // TODO: refactor this out into a util with params for story id
  const onReadingListEntrySelect = (id: string, value: boolean) => {
    if (!storyId) return;

    if (!value) {
      dispatch(deleteReadingListStory({ id, story_id: storyId }));
      return;
    }

    dispatch(postReadingListStory({ id, story_id: storyId }));
  };

  const onReadingExperienceSelect = (mode: ReadingExperience) => {
    dispatch(updateMode(mode));
  };

  // TODO: refactor this out into a util with params for user and story id
  const onDownloadAnswers = () => {
    if (!storyId) return;

    fetchAuthedData(`/responses/pdf/${storyId}`, user.jwt || '').then(
      (response) => {
        const expectedFilename = response.headers.has('content-disposition')
          ? response.headers
              .get('content-disposition')
              ?.split(';')[1]
              ?.split('=')[1]
          : undefined;

        const filename = expectedFilename || `${storyId}.pdf`;

        response.blob().then((blob) => {
          const fileURL = window.URL.createObjectURL(blob);

          // Create, append, then remove a temporary link element
          const alink = document.createElement('a');
          alink.href = fileURL;
          alink.download = filename;
          alink.id = 'downloadLink';
          alink.click();
          alink.remove();
        });
      },
    );
  };

  // TODO: refactor this out into a util with params for story id
  const onGoogleClassroomSubmit = () => {
    if (!storyId) return;
    localStorage.setItem('googleClassroom', 'true');
    localStorage.setItem('submittedStory', storyId);
    window.location.href = `${process.env.GATSBY_READER_DATA_API_URL}/responses/classroom/google`;
  };

  // TODO: refactor into its own component
  const cardArt = (
    <VerticalComicCardArt
      forceSmall
      lowerLayer={
        <SourcedImage
          source={mobile.find((i) => i.type === 'vertical')?.lowerLayer}
        />
      }
      midLayer={
        <SourcedImage
          source={mobile.find((i) => i.type === 'vertical')?.midLayer}
        />
      }
      upperLayer={
        <SourcedImage
          source={mobile.find((i) => i.type === 'vertical')?.upperLayer}
        />
      }
    />
  );

  const readingBadge = badges.find((badge) => badge.trigger === 'reading');
  const interactiveBadge = badges.find(
    (badge) => badge.trigger === 'interactive',
  );

  return (
    <>
      <span id="top" />
      <StoryHeroBanner heroBanner={heroBanner}>
        <StoryIntro
          title={title}
          genres={genres}
          creators={creators}
          country={countries[0]}
          prologue={description}
          cardArt={cardArt}
          hasUser={hasUser}
          hasBookmark={listCount > 0}
          onBookmarkClick={onBookmarkClick}
          onShareClick={() => {
            setShareOverlay(true);
          }}
          onShowInfoClick={handleShowInfo}
        />
        {/* refactor into its own component */}
        {showInfo && (
          <StoryInfoMobile
            summary={<StoryInfoContentSummary text={description} />}
            location={
              <StoryInfoContentLocation
                name={location?.name || ''}
                date={location?.date || ''}
                description={location?.description}
                image={<SourcedImage source={location?.mobile} />}
              />
            }
            characters={
              <StoryInfoContentCharacters name="Characters">
                {characters.map((character) => {
                  return (
                    <StoryInfoContentCharacter
                      key={character.name}
                      name={character.name ?? ''}
                      bio={character.bio}
                      age={character.age ?? ''}
                      location={character.location}
                      image={<SourcedImage source={character.mobile} />}
                    />
                  );
                })}
              </StoryInfoContentCharacters>
            }
            authors={
              <StoryInfoContentAuthors
                creators={creators.map((creator) => {
                  return {
                    creator: {
                      name: creator.creator.name,
                      bio: creator.creator.bio,
                      location: creator.creator.location,
                      image: creator.creator?.image,
                    },
                    role: creator.role,
                  };
                })}
              />
            }
            medium={
              <StoryInfoContentMedium
                name={medium?.name || ''}
                date={medium?.date || ''}
                description={medium?.description}
                image={<SourcedImage source={medium?.mobile} />}
              />
            }
          />
        )}
        <StoryInfo
          summary={<StoryInfoContentSummary text={description} />}
          location={
            <StoryInfoContentLocation
              name={location?.name || ''}
              date={location?.date || ''}
              description={location?.description}
              image={<SourcedImage source={location?.desktop} />}
            />
          }
          characters={
            <StoryInfoContentCharacters name="Characters">
              {characters.map((character) => {
                return (
                  <StoryInfoContentCharacter
                    key={character.name}
                    name={character.name ?? ''}
                    bio={character.bio}
                    age={character.age ?? ''}
                    location={character.location}
                    image={<SourcedImage source={character.desktop} />}
                  />
                );
              })}
            </StoryInfoContentCharacters>
          }
          authors={
            <StoryInfoContentAuthors
              creators={creators.map((creator) => {
                return {
                  creator: {
                    name: creator.creator.name,
                    bio: creator.creator.bio,
                    location: creator.creator.location,
                    image: creator.creator?.image,
                  },
                  role: creator.role,
                };
              })}
            />
          }
          medium={
            <StoryInfoContentMedium
              name={medium?.name || ''}
              date={medium?.date || ''}
              description={medium?.description}
              image={<SourcedImage source={medium?.desktop} />}
            />
          }
        />
      </StoryHeroBanner>
      <StoryPagePanels>
        <StyledStoryPage>
          {panels?.map((panel) => {
            if (isPanelInteractive(panel)) {
              if (user.mode === ReadingExperience.INTERACTIVE) {
                return <PanelContainer panel={panel} />;
              } else {
                return null;
              }
            }
            return <PanelContainer panel={panel} />;
          })}
        </StyledStoryPage>
        {hasUser && (
          <StoryPageAnswers>
            <Header6>Download a pdf of your answers</Header6>
            <StoryPageAnswerButtons>
              <Box my="1">
                <Button1
                  variant="primary"
                  palette="blue"
                  onClick={onDownloadAnswers}
                  iconRight={<Icon icon={downloadIcon} />}
                >
                  Download PDF
                </Button1>
              </Box>
              <Box my="1">
                <Button1
                  variant="primary"
                  palette="blue"
                  onClick={onGoogleClassroomSubmit}
                  iconRight={<Icon icon={downloadIcon} />}
                >
                  Submit to Google Classroom
                </Button1>
              </Box>
            </StoryPageAnswerButtons>
          </StoryPageAnswers>
        )}
        {!hasUser && (
          <StoryPageAnswers>
            <Header7>Sign in to earn badges and unlock more features</Header7>
            <StoryPageAnswerButtons>
              <Box my="1">
                <Button1
                  variant="primary"
                  palette="blue"
                  onClick={() => {
                    navigate('/onboard/signIn');
                  }}
                >
                  Sign in
                </Button1>
              </Box>
            </StoryPageAnswerButtons>
          </StoryPageAnswers>
        )}

        <SocialMediaBar
          onShare={redirectOnShareStory(
            title,
            convertSanityBlockToPlainText(description),
          )}
        />
      </StoryPagePanels>
      <StyledStoryEndPage>
        <StyledStoryPage>
          {hasUser && readingBadge && (
            <StoryPageBadgeDisplay>
              <BadgeDisplay
                title={readingBadge.name}
                storyTitle={title}
                image={<SourcedImage source={readingBadge.images.full} />}
              />
            </StoryPageBadgeDisplay>
          )}
          {hasUser && interactiveBadge && (
            <StoryPageBadgeDisplay>
              <BadgeDisplay
                title={interactiveBadge.name}
                storyTitle={title}
                image={<SourcedImage source={interactiveBadge.images.full} />}
              />
            </StoryPageBadgeDisplay>
          )}
          <StoryPageCreators>
            <Header6 style={{ fontWeight: 700, marginBottom: `1.6rem` }}>
              About the Artists
            </Header6>
            <CreatorWrapper>
              {creators.map(({ role, creator: { name, bio, location } }) => {
                return (
                  <CreatorBio
                    key={name}
                    locations={[location || '']}
                    name={name}
                    bio={bio}
                    role={role}
                    solo
                  />
                );
              })}
            </CreatorWrapper>
          </StoryPageCreators>
          {/* refactor into its own component */}
          <RecommendedReading>
            {recommended
              .filter(
                (
                  entry,
                ): entry is SanityStoryRecommended & {
                  story: typeof RecommendedStory;
                } => {
                  return !!entry && !!entry;
                },
              )
              .map((entry) => {
                return (
                  <RecommendedStory
                    key={entry._key}
                    title={entry.title || ''}
                    authors={entry.authors?.join(', ') || ''}
                    tags={entry.tags?.join(', ') || ''}
                    url={entry.url || ''}
                    image={
                      <SourcedImage
                        source={entry.image as Maybe<SanityImage>}
                      />
                    }
                  />
                );
              })}
          </RecommendedReading>
          {/* refactor into its own component */}
          {recentlyReadEntries.length > 0 && (
            <RecentlyRead>
              {recentlyReadEntries
                .filter(
                  (
                    entry,
                  ): entry is RecentlyReadStoryResult & {
                    story: typeof RecentlyReadStory;
                  } => {
                    return !!entry && !!entry.story;
                  },
                )
                .map((entry) => {
                  return (
                    <RecentlyReadStory
                      key={entry.story.id}
                      title={entry.story.title}
                      onClick={() => {
                        navigate(`/story/${entry.story.id}`);
                      }}
                      image={
                        <SourcedImage
                          source={entry.story.image as Maybe<SanityImage>}
                        />
                      }
                    />
                  );
                })}
            </RecentlyRead>
          )}
          {hasUser && !hasFeedback && (
            <ShareFeedback onSubmit={handleFeedback} />
          )}
        </StyledStoryPage>
      </StyledStoryEndPage>
      <StoryModeControl
        hasUser={hasUser}
        onReadingExperienceSelect={onReadingExperienceSelect}
        readingExperience={user.mode}
      />
      {hasUser && readingListOverlay && (
        <ReadingListsOverlay
          title="My Reading Lists"
          readingListEntries={readingListEntries}
          onReadingListEntrySelect={onReadingListEntrySelect}
          onReadingListChange={onReadingListCreate}
          onRequestClose={() => {
            setReadingListOverlay(false);
          }}
        />
      )}
      {shareOverlay && (
        <ShareOverlay
          title="Share"
          link={window.location.href}
          onShare={redirectOnShareStory(
            title,
            convertSanityBlockToPlainText(description),
          )}
          onRequestClose={() => {
            setShareOverlay(false);
          }}
        />
      )}
    </>
  );
};
